import Dashboard from './views';

const App = () => {

  return (
      <Dashboard />
  );
}

export default App;
