import { VARIABLE } from '../../../../constant'

const Doctor = `${VARIABLE.driveURL}13q9xKe-aVq8K8dKcV0-SdJLVGKesA7iR`;
const Doctor2 = `${VARIABLE.driveURL}1chB2oNU8O6u6tfrikncxB2WQxS5myzei`;
const Doctor3 = `${VARIABLE.driveURL}1BL4uFzKbnvrYXL-DxNwNt7VoBn--x3dv`;
const Doctor4 = `${VARIABLE.driveURL}1usbEkXpiVg3U88cPHoF0XHBkIia1XvsE`;
const Doctor5 = `${VARIABLE.driveURL}1MKX3EGjY9Dz2OLD3og6-2HQRYHSflLgi`;
const Doctor7 = `${VARIABLE.driveURL}1wpN7kQYRWMSqkKMCsH8nYTmT4QnEAX1D`;
const Cusomer_1 = `${VARIABLE.driveURL}140iAtlVfbzZ9RXwJAEBS8J1dE_47ItBD`;
const Cusomer_2 = `${VARIABLE.driveURL}1kxxAmVfMY8OlH7G_KzeI0UdTum8vWL5z`;
const Cusomer_3 = `${VARIABLE.driveURL}1YLvNwbts7vPrL3Lw-jrbgEflGkhu5gh9`;
const Nurse = `${VARIABLE.driveURL}1tOUqeCPEcjMuWgsamC8ipNeoukAzpYP-`;
const DoctorCombo = `${VARIABLE.driveURL}1uatXhVfuauVD8VNsUcFjGGsiywJiwH1M`;
const BackgroundContactUs = `${VARIABLE.driveURL}1-TbyBvnEEMbGZ9T2xqMLioLkT9nSOUGr`;

export {
    Doctor,
    Doctor2,
    Doctor3,
    Doctor4,
    Doctor5,
    Doctor7,
    Nurse,
    DoctorCombo,
    Cusomer_1,
    Cusomer_2,
    Cusomer_3,
    BackgroundContactUs
}