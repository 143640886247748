import {
  Delete,
  StarBorder,
  StarHalf,
  Star,
  AddShoppingCart,
  SavedSearch,
  FavoriteBorder,
  Close,
  ShoppingCart,
  Menu,
  Inventory,
  Troubleshoot,
  TrendingUp,
  ScreenSearchDesktop,
  Reviews,
  ManageAccounts,
  Logout,
  Analytics,
  Storefront,
} from '@mui/icons-material';
import { FaCcPaypal } from 'react-icons/fa';
import { RiVisaLine } from 'react-icons/ri';
import {
  ImUser,
  ImLinkedin2,
  ImFacebook,
  ImGooglePlus,
  ImTwitter,
} from 'react-icons/im';

export const ICONS: any = {
  DeleteIcon: Delete,
  StarBorderIcon: StarBorder,
  StarHalfIcon: StarHalf,
  StarIcon: Star,
  AddShoppingCartIcon: AddShoppingCart,
  SavedSearchIcon: SavedSearch,
  FavoriteBorderIcon: FavoriteBorder,
  FaCcPaypalIcon: FaCcPaypal,
  RiVisaLineIcon: RiVisaLine,
  CloseIcon: Close,
  ImUserIcon: ImUser,
  ImLinkedin2Icon: ImLinkedin2,
  ImFacebookIcon: ImFacebook,
  ImGooglePlusIcon: ImGooglePlus,
  ImTwitterIcon: ImTwitter,
  ShoppingCartIcon: ShoppingCart,
  MenuIcon: Menu,
  InventoryIcon: Inventory,
  TroubleshootIcon :Troubleshoot,
  TrendingUpIcon: TrendingUp,
  ScreenSearchDesktopIcon: ScreenSearchDesktop,
  ReviewsIcon: Reviews,
  ManageAccountsIcon: ManageAccounts,
  LogoutIcon: Logout,
  AnalyticsIcon: Analytics,
  StorefrontIcon: Storefront,
};
