import { useState, useEffect, useReducer } from 'react';
import './App.css';
import { mode } from './theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeContext, themeReducer, initialState } from './globalState';
import { routes } from './routes';
import {
  BrowserRouter, Routes, Route
} from 'react-router-dom';

const App = () => {

  const [themeMode, setThemeMode] = useState('');
  const themeVariables = createTheme(themeMode === 'LIGHTMODE' ? mode.light : mode.dark);
  const [state, dispatch] = useReducer(themeReducer, initialState)

  useEffect(() => {
    const themeMode = localStorage.getItem('mode');
    setThemeMode(themeMode === 'LIGHTMODE' ? 'LIGHTMODE': 'DARKMODE');
    dispatch({type : themeMode === 'LIGHTMODE' ? 'LIGHTMODE': 'DARKMODE'})
  }, [state]);
  
  return (
    <ThemeContext.Provider value={{state, dispatch}}>
      <ThemeProvider theme={themeVariables}>
        <BrowserRouter>
          <Routes>
            {
              routes.map((item: any) => (<Route path={item.path} element={item.component} />))
            }
        </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
