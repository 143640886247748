import Logo from '../assert/svg/logo.svg';
import { VARIABLE } from '../../../constant'
const EarPhoneImage = `${VARIABLE.driveURL}1iC_B9JfLKysyE3cXq26X4JqusoGfjqKb`;
const FidgetImage = `${VARIABLE.driveURL}1uoz_uHg7cjiZHtp46LbY4IChvlYBrI7w`;
const GoogleImage = `${VARIABLE.driveURL}14I2Y5ClvThObQOYWCbruc3BiPAUWuYvk`;
const HeadPhoneImage = `${VARIABLE.driveURL}1obEyCY1E6DdA12Be9RrVOnQ7gbvXgw25`;
const IOSImage = `${VARIABLE.driveURL}15Dn6O9PFXCPMnFQFrM6XWgYrP_-2rm_v`;
const MiniBotImage = `${VARIABLE.driveURL}1zFqAIjAhUXSsqcq7VK55bIO5dRZnpx9U`;
const MosquitoImage = `${VARIABLE.driveURL}1pve2oUcbJy54BDDDzb_Yln439Wbl4F7t`;
const TechnologyImage = `${VARIABLE.driveURL}1eUZwKjjwT-gPaRJj6DebRTD6UiJvgdZe`;
const ClothesImage = `${VARIABLE.driveURL}1-pDhUCmUyBHkRe0lLBMaGz1I8kN-to-a`;
const UserImage = `${VARIABLE.driveURL}1X-Gq62JWKctMY9syRoBeoBHvcXlXzk7Q`;
const BohleImage = `${VARIABLE.driveURL}1cZp18hdZi7AbPY7anhrZ6jiCb6Ma7yel`;
const ShoeImage = `${VARIABLE.driveURL}1E7yH8FKAXYnC7HoBqGD2EadDloiIRYx_`;
const PaggiaroImage = `${VARIABLE.driveURL}122KAiZgaNmR6nRmL2Xm2mA36636qNphn`;
const CoatImage = `${VARIABLE.driveURL}1-o0UQKctGW_lSUZdr8BtxHmvoI2SD9mg`;
const WalletImage = `${VARIABLE.driveURL}1dy_IegJo4cWWX1iVy373UimIDTydHSCz`;
const AppleImage = `${VARIABLE.driveURL}1A8bynMx_W_9nGTK19R1ItCLix2gw0BNS`;

export {
  AppleImage,
  EarPhoneImage,
  FidgetImage,
  GoogleImage,
  HeadPhoneImage,
  IOSImage,
  MiniBotImage,
  MosquitoImage,
  TechnologyImage,
  Logo,
  UserImage,
  ClothesImage,
  BohleImage,
  ShoeImage,
  PaggiaroImage,
  CoatImage,
  WalletImage
};
