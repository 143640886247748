import styled from '@emotion/styled';

const Container = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1c1c24',
  flexDirection: 'column',
  padding: '20px',
})

const PageNotFoundButton = styled.button({
  padding: '20px',
  background: '#39ac31',
  outline: 'none',
  borderRadius: '20px',
  margin: '10px 0',
  border: 'none',
  color: '#ffffff',
  cursor: 'pointer'
})

const PageHeading = styled.h1({
  color: '#ffffff'
})


const NotFound = () => {
  return (
    <Container>
    <PageHeading>Page Not Found</PageHeading>
    <a href={'https://www.codewora.com/'}><PageNotFoundButton>Go To Home</PageNotFoundButton></a>
    </Container>
  )
}

export default NotFound