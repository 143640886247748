export const loginRegistrationRouteVersion1 = {
  LOGIN: '/login-registation/version1',
  REGISTRATION: '/login-registation/version1/registration',
}

export const REGEX = {
  EMAIL: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
  MOBILE: /^[0-9]{10}$/i,
};

export const ENABLE_FIREBASE = false
