import noahErickson from '../../../image/noah-erickson.jpg'
import designecologist from '../../../image/designecologist.jpg'
import markusSpiske from '../../../image/markus-spiske.jpg'
import pixabay from '../../../image/pixabay.jpg'

export const productDetails = [
  { url: noahErickson, title: 'Mobile', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.", price: '$234', count: 4 },
  { url: designecologist, title: 'Desktop', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.", price: '$234', count: 4 },
  { url: markusSpiske, title: 'Trading', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.", price: '$234', count: 4 },
  { url: pixabay, title: 'Bulb', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.", price: '$234', count: 4 },
]