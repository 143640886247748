import AdminDashboardVersion1 from '../adminTemplateVersion1';
import StepperForm from '../form/stepperForm/Steeper';
import RegistrationFormNeumorphismVersion1 from '../form/loginForm/neuomorphism/loginFromVersion1';
import NotFound from '../notFound';

import ProductView from '../ecommerce/ecommerce-1/productView';
import SearchProductHomepage from '../ecommerce/ecommerce-1/searchHomepage';
import CartSection from '../ecommerce/ecommerce-1/cart';
import Homepage from '../ecommerce/ecommerce-1/homepage';
import { routeNameEcommerceVersion1 } from '../ecommerce/ecommerce-1/constant';

import { ReactNode } from 'react';
import { loginRegistrationRouteVersion1 } from '../login-registration/version1/constant';
import Login from '../login-registration/version1/loginForm';
import Registration from '../login-registration/version1/registrationForm';


import HomapageHospital from '../hospital/hospital-1/homepage'
import DoctorDetails from '../hospital/hospital-1/doctorDetails'
import ContactUs from '../hospital/hospital-1/contactUs'
import BookAppointment from '../hospital/hospital-1/bookAppointment'
import { routeNameHospitalVersion } from '../hospital/hospital-1/constant'


type IRoute = {
  path: string,
  component: JSX.Element | JSX.Element[] | ReactNode
}[]


export const routes: IRoute = [
  {
    path: '/admindashboardversion1',
    component: <AdminDashboardVersion1 />
  },
  {
    path: '/stepperformversion1',
    component: <StepperForm />
  },
  {
    path: '/registrationformneuoversion1',
    component: <RegistrationFormNeumorphismVersion1 />
  },
  {
    path: routeNameEcommerceVersion1.HOMEPAGE,
    component: <Homepage />,
  },
  {
    path: routeNameEcommerceVersion1.CART,
    component: <CartSection />,
  },
  {
    path: routeNameEcommerceVersion1.SEARCHPRODUCT,
    component: <SearchProductHomepage/>,
  },
  {
    path: routeNameEcommerceVersion1.PRODUCTVIEW,
    component: <ProductView />,
  },
  {
    path: loginRegistrationRouteVersion1.LOGIN,
    component: <Login />,
  },
  {
    path: loginRegistrationRouteVersion1.REGISTRATION,
    component: <Registration />,
  },
  {
    path: routeNameHospitalVersion.HOMEPAGE,
    component: <HomapageHospital />,
  },
  {
    path: routeNameHospitalVersion.HOMEPAGE,
    component: <Homepage />,
  },
  {
    path: routeNameHospitalVersion.BOOKAPPOINTMENT,
    component: <BookAppointment />,
  },
  {
    path: routeNameHospitalVersion.CONTACTUS,
    component: <ContactUs />,
  },

  {
    path: routeNameHospitalVersion.DOCTORDETAILS,
    component: <DoctorDetails />,
  },
  {
    path: '*',
    component: <NotFound />
  },
]